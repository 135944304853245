import React, {useEffect, useState} from 'react';
import {EyeOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {Modal} from 'antd';

import {config} from 'data';
import {url} from 'helpers';
import {useQuery, useTable} from 'hooks';
import {complaintService} from 'services';
import {DashboardLayout} from 'layouts';
import {ComplaintDate, ComplaintReason, ComplaintStatus, ComplaintType, User} from 'components/layout';
import {Image, Table} from 'components/ui';
import {Complaint} from 'types/api';
import {TableColumns} from 'types/components';

import styles from './styles.module.css';
import {Uuid} from "../../types/general";
import PhotoSwiper from "../../components/ui/PhotoSwiper";


const ReportedUsersPage = () => {
    const navigate = useNavigate();

    const [modalUserId, setModalUserId] = useState<Uuid | null>(null);
    const table = useTable<Complaint>(config.COMPLAINTS_QUERY_KEY);

    const page = table.pagination ? table.pagination.current : 1;

    const complaintsQuery = useQuery({
        queryKey: [config.COMPLAINTS_QUERY_KEY, page],
        queryFn: () => complaintService.fetchComplaints({page}),
    });

    const handleViewClick = (complaint: Complaint) => () => navigate(url.toReportedUser(complaint.id));

    useEffect(() => {
        if (complaintsQuery.data) {
            const {content, pageable, totalElements} = complaintsQuery.data;

            table.setData(content);

            table.setPagination({
                current: pageable.pageNumber,
                pageSize: pageable.pageSize,
                total: totalElements,
            });
        }
    }, [table.setData, table.setPagination, complaintsQuery.data]); /* eslint-disable-line react-hooks/exhaustive-deps */

    const columns: TableColumns<Complaint> = [
        {
            className: styles.table__user,
            key: 'user',
            title: 'User',
            render: (_, complaint) => <User user={complaint.user}/>,
        }, {
            key: 'type',
            title: 'Type',
            render: (_, complaint) => <ComplaintType type={complaint.type}/>,
        },
        {
            className: styles.table__user,
            key: 'image',
            title: 'Image',
            render: (_, complaint) =>
                complaint.user.image && complaint.user.image.endsWith('null') ? (
                    <div onClick={(e) => e.stopPropagation()} style={{textAlign: 'left'}}>
                        No image
                    </div>
                ) : (
                    <Image
                        width={100}
                        height={100}
                        style={{objectFit: 'cover'}}
                        wrapperClassName={styles.card__image}
                        src={complaint.user.image}
                        preview={false}
                        alt={complaint.user.name}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalUserId(complaint.user.id);
                        }}
                    />
                )
        },
        {
            className: styles.table__reason,
            key: 'reason',
            title: 'Reason',
            render: (_, complaint) => <ComplaintReason reason={complaint.message}/>,
        }, {
            className: styles.table__user,
            key: 'reporter',
            title: 'Reporter',
            render: (_, complaint) => <User user={complaint.createdBy}/>,
        }, {
            key: 'date',
            title: 'Date',
            render: (_, complaint) => <ComplaintDate date={complaint.createdDate}/>,
        }, {
            key: 'status',
            title: 'Status',
            render: (_, complaint) => <ComplaintStatus status={complaint.status}/>,
        }, {
            key: 'actions',
            title: 'Actions',
            render: (_, complaint) => (
                <Table.Actions
                    buttons={[{
                        title: 'View',
                        icon: <EyeOutlined/>,
                        onClick: handleViewClick(complaint),
                    }]}
                />
            ),
        },
    ];

    return (
        <>
            <DashboardLayout
                title="Reported users"
                heading="Reported users"
            >
                <Table<Complaint>
                    className={styles.table}
                    columns={columns}
                    dataSource={table.data}
                    pagination={table.pagination}
                    loading={complaintsQuery.isFetching}
                    rowKey={(complaint) => complaint.id}
                    onRow={(complaint) => ({onClick: handleViewClick(complaint), style: {cursor: 'pointer'}})}
                    onChange={table.onChange}
                />
            </DashboardLayout>
            <Modal
                open={modalUserId !== null}
                footer={null}
                onCancel={() => setModalUserId(null)}
                centered
                destroyOnClose
            >
                <div style={{padding: 40}}><PhotoSwiper userId={modalUserId}/></div>
            </Modal></>
    );
};

export default ReportedUsersPage;
