import React, {useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { attractiveUsersService } from 'services';
import { Navigation, Pagination } from 'swiper/modules';

import {useQuery} from "@tanstack/react-query";
import {Uuid} from "../../../types/general";
import {Image} from "antd";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "./style.css";

type CarouselProps = {
    userId: Uuid | null;
}

const PhotoCarousel = (props: CarouselProps) => {
    const { userId } = props

    const [photos, setPhotos] = React.useState<string[]>([]);

    const userPhotosQuery = useQuery({
        queryKey: [userId],
        queryFn: () => attractiveUsersService.getUser(userId),
    });

    useEffect(() => {
        if (userPhotosQuery.data) {
            const { photos } = userPhotosQuery.data;
            setPhotos(photos.map(photo => photo.image));
        }
    }, [userPhotosQuery?.data]);


    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <div style={{ padding: 20}}>

            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={pagination}
                navigation={true}
                modules={[Pagination, Navigation]}>
                {photos.map((imageUrl, index) => <SwiperSlide key={index}><Image src={imageUrl}
                                                                                 preview={false}/></SwiperSlide>)}
            </Swiper>
        </div>
    )
}

export default PhotoCarousel
