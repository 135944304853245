import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { config, dayjs } from 'data';
import { useMutation, useQuery, useTable } from 'hooks';
import { attractiveUsersService } from 'services';
import { DashboardLayout } from 'layouts';
import { Image, Table} from 'components/ui';
import { AttractiveUser } from 'types/api';
import { TableColumns } from 'types/components';
import { Input, Space } from "antd";
import styles from './styles.module.css';
import { useQueryClient } from "@tanstack/react-query";
import { useDebounce } from 'use-debounce';

const UsersPage = () => {
  const table = useTable<AttractiveUser>(config.USERS_QUERY_KEY);

  const page = table.pagination ? table.pagination.current : 1;

  const queryClient = useQueryClient();

  const [searchString, setSearchString ] = useState('');

  const [search]= useDebounce(searchString, 1000)


  useEffect(() => {
    table.setPagination({ current: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);


  const attractiveUsersQuery = useQuery({
    queryKey: [config.USERS_QUERY_KEY, page, search],
    queryFn: () => attractiveUsersService.fetchUsers({ page, searchText: search }),
  });

  const invalidateUserQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: [config.USERS_QUERY_KEY, page] });
  };


  const updateUserMutation = useMutation({
    mutationFn: attractiveUsersService.updateUser,
    onSuccess: invalidateUserQueries,
  });

  const updateUserBotModeMutation = useMutation({
    mutationFn: attractiveUsersService.updateUserBotMode,
    onSuccess: invalidateUserQueries,
  });


  useEffect(() => {
    if (attractiveUsersQuery.data) {
      const { content, pageable, totalElements } = attractiveUsersQuery.data;

      table.setData(content);

      table.setPagination({
        current: pageable.pageNumber,
        pageSize: pageable.pageSize,
        total: totalElements,
      });
    }
  }, [table.setData, table.setPagination, attractiveUsersQuery.data]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const columns: TableColumns<AttractiveUser> = [

    {
      className: styles.table__user,
      key: 'type',
      title: 'Attractive',
      render: (_, user) =>  <Switch size="small" loading={updateUserMutation.isPending} checked={user.attractive} onChange={async () => {
        updateUserMutation.mutate({userId: user.id, attractive: !user.attractive})
      }}/>
    },
    {
      className: styles.table__user,
      key: 'bot',
      title: 'Bot',
      render: (_, user) =>  <Switch size="small" loading={updateUserBotModeMutation.isPending} checked={user.isBot} onChange={async () => {
        updateUserBotModeMutation.mutate({userId: user.id, bot: !user.isBot})
      }}/>
    },

    {
      className: styles.table__user,
      key: 'createdDate',
      title: 'Created date',
      render: (_, user) => dayjs(user.createdDate).format('LL'),
    },

    {
      className: styles.table__user,
      key: 'email',
      title: 'Email',
      render: (_, user) => user.email,
    },

    {
      className: styles.table__user,
      key: 'name',
      title: 'Name',
      render: (_, user) => user.name,
    },

    {
      className: styles.table__user,
      key: 'image',
      title: 'Image',
      render: (_, user) =>
          user.image && user.image.endsWith('null') ? (
              <div style={{ textAlign: 'left' }}>No image</div>
          ) : (
              <Image
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                  wrapperClassName={styles.card__image}
                  src={user.image}
                  alt={user.name}
              />
          )
    },
    {
      className: styles.table__user,
      key: 'birthday',
      title: 'Birthday',
      render: (_, user) => user.birthday,
    },

    {
      className: styles.table__user,
      key: 'country',
      title: 'Country',
      render: (_, user) => user.country,
    },

    {
      className: styles.table__user,
      key: 'address',
      title: 'Address',
      render: (_, user) => user.address,
    },

    {
      className: styles.table__user,
      key: 'plan',
      title: 'Plan',
      render: (_, user) => user.subscriptionPlan,
    },
  ];

  return (
    <DashboardLayout
      title="Users"
      heading="Users"
    >

        <div style={{width: '100%'}}>
          <Space.Compact style={{width: '30%'}}>
            <Input placeholder="Search by email/name" className={styles.search}
                   onChange={(e) => setSearchString(e.target.value)}
            />
          </Space.Compact>
        </div>

      <br/>
      <Table<AttractiveUser>
          className={styles.table}
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        loading={attractiveUsersQuery.isFetching}
        rowKey={(user) => user.id}
        onChange={table.onChange}
      />
    </DashboardLayout>
  );
};

export default UsersPage;
