import { axios } from 'data';
import { Uuid } from 'types/general';

import {
  ComplaintSortField,
  FetchAttractiveUsersResponse,
  FetchComplaintsParams,
  SortOrder, UserWithPhotos,
} from 'types/api';

const fetchUsers = async (params?: FetchComplaintsParams) => {
  const response = await axios.get<FetchAttractiveUsersResponse>('/admin/user', {
    params: {
      sort: [
        `${ComplaintSortField.STATUS},${SortOrder.ASC}`,
        `${ComplaintSortField.DATE},${SortOrder.ASC}`,
      ],
      ...params,
    },
  });

  return response.data;
};

const updateUser = async (data: { userId: Uuid, attractive: boolean }) => {
  if (data.attractive) {
    await axios.put(`/admin/user/${data.userId}/attractive`);
  }   else {
    await axios.put(`/admin/user/${data.userId}/unattractive`);
  }
}

const updateUserBotMode = async (data: { userId: Uuid, bot: boolean }) => {
  if (data.bot) {
    await axios.put(`/admin/user/${data.userId}/bot-add`);
  }   else {
    await axios.put(`/admin/user/${data.userId}/bot-remove`);
  }
}

const getUser = async (id: Uuid | null) => {
    const response = await axios.get<UserWithPhotos>(`/admin/user/${id}`);
    return response.data;
}

const attractiveUserService = {
  fetchUsers,
  getUser,
  updateUser,
  updateUserBotMode
};

export default attractiveUserService;
