import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { url } from 'helpers';
import { useAuth, usePreloader } from 'hooks';
import { PrivateRoute, PublicRoute } from 'components/routes';

import {
  DashboardPage,
  ErrorPage,
  InternalErrorPage,
  LoginPage,
  ReportedUserPage,
  ReportedUsersPage,
  UploadedPhotosPage,
} from 'pages';
import UsersPage from "../pages/Users";

const router = createBrowserRouter([
  {
    path: url.toLogin(),
    element: <PublicRoute component={<LoginPage />} />,
    errorElement: <ErrorPage />,
  }, {
    path: url.toInternalError(),
    element: <PublicRoute component={<InternalErrorPage />} />,
  }, {
    path: url.toDashboard(),
    element: <PrivateRoute component={<DashboardPage />} />,
  }, {
    path: url.toReportedUsers(),
    element: <PrivateRoute component={<ReportedUsersPage />} />,
  }, {
    path: url.toReportedUser(':id'),
    element: <PrivateRoute component={<ReportedUserPage />} />,
  }, {
    path: url.toUploadedPhotos(),
    element: <PrivateRoute component={<UploadedPhotosPage />} />,
  },
  {
    path: url.toAttractedUsers(),
    element: <PrivateRoute component={<UsersPage />} />,
  }
]);

const AppProvider = () => {
  const auth = useAuth();
  const preloader = usePreloader();

  useEffect(() => {
    preloader.toggle(!auth.initialized);
  }, [auth.initialized, preloader]);

  return auth.initialized && <RouterProvider router={router} />;
};

const App = {
  Provider: AppProvider,
};

export default App;
